import { get } from "api/BaseApi";
import { Preorder } from "./fetchPreorders";
import { TextilePrice } from "./fetchTextilePrices";
import { Textile } from "./fetchTextiles";

interface CalculatorOverview {
  preorders?: Preorder[];
  price?: TextilePrice;
  has_checkout?: boolean;
  checkout_author_id?: number;
  error?: Errors;
  textile: Textile;
}

interface Errors {
  errors: string[]
}

export const getOrderCalculatorOverview = (textileOrderId: number):Promise<CalculatorOverview> => {
  return get(`/textile_screen/order_calculator?textile_order_id=${textileOrderId}`);
}